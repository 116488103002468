.home {
    .learners {
        background-color: #5e9bff;
        padding-bottom: 40px;
    }
    .tests {
        background-color: #ff7f36;
    }
    .taxi-fare {
        background-color:#731ff1;
    }
}


.drop-in {
    animation: drop-in 1s ease 200ms backwards;
   }

   .drop-in-2 {
    animation: drop-out 1200ms ease 500ms backwards;
   }

   @keyframes drop-out {
    from {
     opacity: 0;
     transform: translateY(100px);
    }
    to {
     opacity: 1;
     transform: translate(0px);
    }
   }
@keyframes drop-in {
    from {
     opacity: 0;
     transform: translateY(-100px);
    }
    to {
     opacity: 1;
     transform: translate(0px);
    }
   }
