.showcase {
    .show1 {
        .screenshot{
            height:fit-content
        }
    }
    .show2 {
        .info {
            padding: 30px 0;
            a {
                display: block;
                margin-top: 20px;
                @media only screen and (max-width: 768px) {
                    /* For mobile phones: */
                    text-align: center;
                  }

                .googleplay {
                    width: 200px;
                    height: unset;
                }
            }
        }
    }
    img {
        height: fit-content;
        width: 100%;
    }
    .iframe-container{
        padding-right: 15px;
        @media only screen and (max-width: 768px) {
            padding: 0;
          }
        iframe {
            width: 100%;
        }
    }

    h1 {
        font-size: 50px;
        color: white;
        font-weight: 900;
        position: relative;
        @media only screen and (max-width: 768px) {
            /* For mobile phones: */
            text-align: center;
            font-size: 32px;
          }
    }
    p {
        font-size: 24px;
        color: white;
        font-weight: 500;
        @media only screen and (max-width: 768px) {
            /* For mobile phones: */
            text-align: center;
            font-size: 16px;
          }
    }
    h3 {
        color: white;
    }
    ul {
        list-style: none;
        margin: 20px 0;
        li {
            color: white;
        }
    }
    &--order {
        .show1 {
            order: 2;
        }
        .show2 {
            order: 1;
        }
    }
}